<template>
    <div>
        <div class="full">
            <div style="min-height:100vh">
                <img src="./8.jpeg" alt="">
            </div>
            <div style="height:100vh">
                <img src="./首页_一屏.png" alt="">
            </div>
            <div style="height:100vh">
                <img src="./搜索页-历史记录-键盘.png" alt="">
            </div>
        </div>
        <div class="second">
            <div style="min-height:100vh">
                <img src="./7.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./1.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./2.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./3.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./4.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./5.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./6.png" alt="">
            </div>
        </div>
        <!-- <div class="third">
            <div style="min-height:100vh">
                <img src="./3屏.png" alt="">
            </div>
            <div style="min-height:100vh">
                <img src="./首页_已登陆_VIP.png" alt="">
            </div>
        </div> -->
    </div>
</template>
<style scoped>
.full {
  display: block;
}

.second {
  display: none;
}

.third {
  display: none;
}

@media (max-width: 1100px) and (min-width: 818px) {
  .full {
    display: none;
  }
  .second {
    display: block;
  }
}

@media (max-width: 817px) {
  .second {
    display: none;
  }
  .third {
    display: block;
  }
}
</style>

